/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import React from 'react';
import { decorate, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Table, Icon } from 'semantic-ui-react';
import { isStoreLoading, isStoreReady, isStoreError } from '@aws-ee/base-ui/dist/models/BaseStore';
import { swallowError, formatBytes } from '@aws-ee/base-ui/dist/helpers/utils';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import ProgressPlaceHolder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import { getEnvironmentExtractionFileSignedUrl } from '../../helpers/api';
import formatDateTime from '../../helpers/format-date-time';

class DataExtractionFileList extends React.Component {
  componentDidMount() {
    const fileListStore = this.getFileListStore();
    const autoUpdate = this.props.autoUpdate;
    if (fileListStore) {
      swallowError(fileListStore.load());
      if (autoUpdate) {
        fileListStore.startHeartbeat();
      }
    }
  }

  get fileListsStore() {
    return this.props.environmentExtractionFileListsStore;
  }

  getFileListStore() {
    const fileListsStore = this.fileListsStore;
    const environmentId = this.props.dataExtractionRequest
      ? this.props.dataExtractionRequest.environmentId
      : this.props.environmentId;

    return fileListsStore.getEnvironmentExtractionFileListStore(environmentId);
  }

  handleFileRequest = async ({ event, filename }) => {
    event.preventDefault();
    event.stopPropagation();

    const id = this.props.dataExtractionRequest.id;
    const environmentId = this.props.dataExtractionRequest.environmentId;

    const fileSignedUrl = await getEnvironmentExtractionFileSignedUrl({ environmentId, id, filename });

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', fileSignedUrl);
    downloadLink.setAttribute('download', filename);
    downloadLink.click();
  };

  render() {
    const store = this.getFileListStore();
    const allowDownloads = this.props.allowDownloads;
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder />;
    } else if (isStoreReady(store)) {
      content = (
        <Table striped className="scrollable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Filename</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>Last Modified</Table.HeaderCell>
              <Table.HeaderCell textAlign="right" collapsing>
                Download
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {store.environmentExtractionFilesList.files.map(file => (
              <Table.Row key={file.filename}>
                <Table.Cell>{file.filename}</Table.Cell>
                <Table.Cell>{formatBytes(file.size)}</Table.Cell>
                <Table.Cell>{formatDateTime(file.lastModified.toISOString())}</Table.Cell>
                <Table.Cell
                  className={`${allowDownloads && 'cursor-pointer'}`}
                  textAlign="right"
                  onClick={event => allowDownloads && this.handleFileRequest({ event, filename: file.filename })}
                >
                  <Icon name="cloud download" disabled={!allowDownloads} color={allowDownloads ? 'blue' : 'grey'} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    } else {
      content = null;
    }

    return content;
  }
}

decorate(DataExtractionFileList, {
  fileListsStore: computed,
});

export default inject('environmentExtractionFileListsStore')(observer(DataExtractionFileList));
