/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { decorate, action, computed, runInAction, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Header, Checkbox, Segment, Accordion, Icon, Button } from 'semantic-ui-react';
import c from 'classnames';
import { displaySuccess, displayError } from '@aws-ee/base-ui/dist/helpers/notification';

import StudyFilesTable from './StudyFilesTable';
import StudyPermissionsTable from './StudyPermissionsTable';
import UploadStudyFiles from './UploadStudyFiles';
import CreateStructuredDataTable from './CreateStructuredDataTable';
import StructuredDataTables from './StructuredDataTables';
import { categories } from '../../../dist/models/studies/categories';
import AcceptRejectPendingStudy from './AcceptRejectPendingStudy';
import { grantAdminAccess } from '../../helpers/api';

// expected props
// - study (via props)
// - isSelectable (via props)
// - filesSelection (via injection)
class StudyRow extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.itemsExpanded = false;
      this.permissionsExpanded = false;
      this.adminAccessRequestInProgess = false;
    });

    if (this.props.study.studyType === 'structured') {
      this.config = {
        itemsName: 'Tables',
      };
    } else {
      this.config = {
        itemsName: 'Files',
      };
    }
  }

  get study() {
    return this.props.study;
  }

  get isSelectable() {
    return this.props.isSelectable;
  }

  handleFileSelection = study => {
    const selection = this.props.filesSelection;
    if (selection.hasFile(study.id)) {
      selection.deleteFile(study.id);
    } else {
      const { id, name, description } = study;
      // TODO: actually do different statuses?
      selection.setFile({ id, name, description, accessStatus: 'approved' });
    }
  };

  handleItemsExpanded = () => {
    this.itemsExpanded = !this.itemsExpanded;
  };

  handlePermissionsExpanded = () => {
    this.permissionsExpanded = !this.permissionsExpanded;
  };

  render() {
    const study = this.study;
    const hasAccess = !!study.access;

    const isSelectable = this.isSelectable && hasAccess; // Internal and external guests can't select studies
    const selection = this.props.filesSelection;
    const isSelected = selection.hasFile(study.id);
    const attrs = {};
    const onClickAttr = {};

    const isAdmin = _.get(this.props.userStore, 'user.userRole', undefined) === 'admin';
    const studyCategory = study.category;

    if (isSelected) attrs.color = 'blue';
    if (isSelectable) onClickAttr.onClick = () => this.handleFileSelection(study);

    const underReview = !isAdmin && study.access === 'readonly' && studyCategory === categories.pendingApproval.name;

    return (
      <Segment clearing padded raised className="mb3" {...attrs}>
        <div className="flex">
          <div className="mr2" {...onClickAttr}>
            {isSelectable && <Checkbox checked={isSelected} style={{ marginTop: '3px' }} />}
          </div>
          <div className="flex-auto mb1">
            {this.renderHeader(study, underReview)}
            {this.renderDescription(study)}
            {hasAccess && this.renderItemsAccordion(study)}
            {hasAccess && this.renderPermissionsAccordion(study)}
            {!hasAccess &&
              isAdmin &&
              studyCategory === categories.pendingApproval.name &&
              this.renderRequestReadOnlyAccess(study)}
          </div>
        </div>
      </Segment>
    );
  }

  async handleAdminAccessRequest(study) {
    runInAction(() => {
      this.adminAccessRequestInProgess = true;
    });

    try {
      await grantAdminAccess(study.id);
      displaySuccess('Access granted!');

      await this.study.getPermissionsStore().reload();
      this.study.forceSetAccess('readonly'); // Ugly, I know :(
    } catch (error) {
      displayError(error);
    }
  }

  renderRequestReadOnlyAccess(study) {
    return (
      <div style={{ textAlign: 'center', width: '100%', backgroundColor: '#eee', marginTop: '1em', padding: '1em 0' }}>
        <Button
          color="blue"
          disabled={this.adminAccessRequestInProgess}
          style={{ marginBottom: '1em' }}
          onClick={() => this.handleAdminAccessRequest(study)}
        >
          Obtain Access
        </Button>
        <Header.Subheader>Get access to this project to see files/tables and to create a workspace</Header.Subheader>
      </div>
    );
  }

  renderHeader(study, underReview) {
    const isSelectable = this.isSelectable; // Internal and external guests can't select studies
    const onClickAttr = {};

    const isAdmin = _.get(this.props.userStore, 'user.userRole', undefined) === 'admin';
    const studyCategory = study.category;

    let uploadLocationEnabled = study.uploadLocationEnabled;
    if (studyCategory === categories.pendingApproval.name && isAdmin) {
      uploadLocationEnabled = false;
    }

    if (isSelectable) onClickAttr.onClick = () => this.handleFileSelection(study);
    return (
      <>
        {study.studyType !== 'structured' && uploadLocationEnabled && study.access === 'admin' && (
          <UploadStudyFiles dataModel={study} studyId={study.id} floated="right" />
        )}
        {study.studyType === 'structured' && study.access === 'admin' && uploadLocationEnabled && (
          <CreateStructuredDataTable study={study} />
        )}
        {isAdmin && studyCategory === categories.pendingApproval.name && (
          <AcceptRejectPendingStudy study={study} floated="right" />
        )}
        <Header as="h3" color="blue" className={c('mt0', isSelectable ? 'cursor-pointer' : '')} {...onClickAttr}>
          {study.name}
          {underReview && (
            <span style={{ border: '1px solid red', fontSize: '0.8em', color: 'red', marginLeft: '1em' }}>
              Under Review
            </span>
          )}
          <Header.Subheader>
            <span className="pt1 fs-8 color-grey">{`Study: ${study.id}`}</span>
            {study.projectId && <div className="fs-8 color-grey"> {`Projects: ${study.projectId.join(', ')}`}</div>}
            {study.category === categories.pendingApproval.name && (
              <div className="fs-8 color-grey"> {`Submitted by: ${study.createdByUsername}`}</div>
            )}
            {study.category === categories.rejectedApproval.name && (
              <div className="fs-8 color-grey"> {`Rejected by: ${study.updatedByUsername}`}</div>
            )}
          </Header.Subheader>
        </Header>
      </>
    );
  }

  renderDescription(study) {
    return <div>{study.description}</div>;
  }

  renderItemsAccordion(study) {
    if (study.isOpenDataStudy) return null;
    if (!study.uploadLocationEnabled) return null;
    const expanded = this.itemsExpanded;

    return (
      <Accordion className="mt2">
        <Accordion.Title active={expanded} index={0} onClick={this.handleItemsExpanded}>
          <Icon name="dropdown" />
          <b>{this.config.itemsName}</b>
        </Accordion.Title>
        <Accordion.Content active={expanded}>
          {expanded && study.studyType !== 'structured' && study.uploadLocationEnabled && (
            <div className="mb2">
              <StudyFilesTable dataModel={study} />
            </div>
          )}
          {expanded && study.studyType === 'structured' && (
            <div className="mb2">
              <StructuredDataTables study={study} />
            </div>
          )}
        </Accordion.Content>
      </Accordion>
    );
  }

  renderPermissionsAccordion(study) {
    if (!study.isOrganizationStudy) return null;
    const expanded = this.permissionsExpanded;

    return (
      <Accordion className="mt0">
        <Accordion.Title active={expanded} index={0} onClick={this.handlePermissionsExpanded}>
          <Icon name="dropdown" />
          <b>Permissions</b>
        </Accordion.Title>
        <Accordion.Content active={expanded}>{expanded && <StudyPermissionsTable study={study} />}</Accordion.Content>
      </Accordion>
    );
  }
}

decorate(StudyRow, {
  handleFileSelection: action,
  handleItemsExpanded: action,
  handlePermissionsExpanded: action,
  study: computed,
  itemsExpanded: observable,
  permissionsExpanded: observable,
  isSelectable: computed,
  adminAccessRequestInProgess: observable,
});

export default inject('filesSelection', 'userStore')(observer(StudyRow));
