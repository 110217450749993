/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { action, decorate, observable, computed, runInAction } from 'mobx';
import { getEnv } from 'mobx-state-tree';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import { Segment, Checkbox, Button, Header, Form, TextArea, Dimmer, Loader } from 'semantic-ui-react';

import { displayError, displaySuccess } from '@aws-ee/base-ui/dist/helpers/notification';
import { swallowError, toUTCDate } from '@aws-ee/base-ui/dist/helpers/utils';

class PendingCard extends React.Component {
  constructor(props) {
    super(props);

    // Standard Mobx mechanism for mutating state safely, in an action
    runInAction(() => {
      this.comment = '';
      this.loading = false;
      this.pendingSelection = [];
      this.transformPlugins = [];
    });
  }

  componentDidMount() {
    swallowError(this.props.approvalsStore.load());
    // Give plugins a chance to alter the checkboxes
    // The plugins can access any stores needed from context and load if necessary.
    // They return the actual transform functions that are run below
    const pluginRegistry = this.props.pluginRegistry;
    const appContext = this.appContext;
    (async () => {
      const transformPlugins = await pluginRegistry.runPlugins(
        'approval-card-components',
        'getItemsCheckBoxTransform',
        appContext,
      );
      runInAction(() => {
        this.transformPlugins = transformPlugins;
      });
    })();
  }

  get appContext() {
    return getEnv(this.props.app) || {};
  }

  handleTypedComment = (event, data) => {
    this.comment = data.value || '';
  };

  handleConfirmation = async approveOrDecline => {
    const store = this.props.approvalsStore;
    const ids = this.pendingSelection;

    runInAction(() => {
      this.loading = true;
    });
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (const id of ids) {
        const result =
          approveOrDecline === 'approve' ? store.approve(id, this.comment) : store.decline(id, this.comment);
        await result; // eslint-disable-line no-await-in-loop
      }
      runInAction(() => {
        this.comment = '';
        this.loading = false;
        this.pendingSelection = [];
      });
      displaySuccess('Submission was successful');
    } catch (err) {
      runInAction(() => {
        this.loading = false;
      });
      displayError(err);
    }
  };

  /**
   * The user has clicked a checkbox associated with an approval request and
   * item. Update the list of selected items.
   *
   * Note that an original approval request can include multiple items and
   * each request/item pair is stored in a separate Approval item.
   *
   * @param id The approval request ID
   *
   * @returns none
   */
  handleRequestSelection = id => {
    const selection = this.pendingSelection;
    if (selection.includes(id)) {
      this.pendingSelection = selection.filter(i => i !== id);
    } else {
      this.pendingSelection = [...selection, id];
    }
  };

  /**
   * The admin user typically approves or declines approval requests, but in
   * the rare case that a mistake was made, it is useful to be able to delete
   * a new approval request (as if it had not happened).
   *
   * Note that an original approval request can include multiple items and
   * each request/item pair is stored in a separate Approval item.
   *
   * @param request The MST model representing the approval request
   *
   * @returns a promise fulfilled when all approval items have been deleted
   */
  handleApprovalDelete = request => {
    const store = this.props.approvalsStore;
    return Promise.all(request.approvals.map(approval => store.delete(approval.id)));
  };

  /**
   * Render an item in a custom way.
   *
   * @param id the requested item ID
   * @param type the requested item type
   *
   * @returns {string} label for requested item
   */
  renderRequestLabel(id, type) {
    return this.props.getApprovalLabel(id, type);
  }

  /**
   * Render a single card representing potentially multiple items that a
   * user has requested approval to access. These cards are only visible to
   * admin users wo can delete, approve, or decline the access request.
   *
   * @param none
   *
   * @returns React component that renders a card for the approval request
   */
  render() {
    const user = this.props.usersStore.asUserObject({ uid: this.props.userType.userIdentifier });
    if (!user) return null;
    return this.renderCard(user, this.props.userType);
  }

  /**
   * Render a card representing a pending request for access to an item.
   *
   * @param user the MST model representing the user requesting access
   * @param request The MST model representing the approval request
   *
   * @returns React component that renders a card for the approval request
   */
  renderCard(user, request) {
    const displayName = user.longDisplayName;
    const createDate = request.createdAt;

    return (
      <Segment className="p3 mb2 cursor-pointer" key={request.id}>
        <Button.Group basic size="medium" floated="right">
          <Button
            icon="trash"
            onClick={() => this.handleApprovalDelete(request)}
            title="Delete this approval request"
          />
        </Button.Group>

        <Header as="h3" color="grey" className="mt0">
          {displayName}
        </Header>
        <Dimmer.Dimmable dimmed={this.loading}>
          <Dimmer active={this.loading} inverted>
            <Loader>Submitting</Loader>
          </Dimmer>
          <div>
            <div className="mb3">
              {`${displayName} requested access to the following ${request.type}.`}
              <br />
              <br />
              The request was made {this.renderDate(createDate)}.
            </div>
            {this.renderApprovalCardComponentPlugins(user, request)}
            {this.renderItemsTable(user, request)}
          </div>
          <div>
            <Form>
              <TextArea
                placeholder="Type your comment here"
                rows={10}
                value={this.comment}
                onInput={this.handleTypedComment}
              />
            </Form>
          </div>
        </Dimmer.Dimmable>
        {this.renderApprovalButtons(user)}
      </Segment>
    );
  }

  renderApprovalButtons(_user) {
    const selection = this.pendingSelection;
    const readyToSubmit = _.size(selection) > 0 && this.comment;

    return (
      <div className="ui two buttons mt2">
        <Button
          basic
          color="green"
          disabled={!readyToSubmit || this.loading}
          onClick={() => this.handleConfirmation('approve')}
        >
          Approve
        </Button>
        <Button
          basic
          color="red"
          disabled={!readyToSubmit || this.loading}
          onClick={() => this.handleConfirmation('decline')}
        >
          Decline
        </Button>
      </div>
    );
  }

  renderItemsTable(user, request) {
    const selection = this.pendingSelection;
    const initialCheckboxes = [];
    _.forEach(request.approvals, approval => {
      initialCheckboxes.push({
        selected: selection.includes(approval.id),
        id: approval.id,
        text: approval.itemId,
        type: approval.type,
      });
    });

    const checkboxes = _.reduce(
      this.transformPlugins,
      (result, plugin) => plugin({ request, checkboxes: result }),
      initialCheckboxes,
    );

    return (
      <div className="mb3 mt3">
        <Header as="h5" color="grey" className="m0 mb1">
          {`Please check the relevant ${request.type}, add a comment and then click Approve or Decline:`}
        </Header>
        {_.map(checkboxes, item => (
          <div key={item.id} className="mt1" onClick={() => this.handleRequestSelection(item.id)}>
            <Checkbox
              label={this.renderRequestLabel(item.text, item.type)}
              checked={this.pendingSelection.includes(item.id)}
            />
          </div>
        ))}
      </div>
    );
  }

  /**
   * Render a date in an informal, Moment-like fashion such as "10 minutes ago"
   * or "next week".
   *
   * @param date the date to render
   *
   * @returns React component for the date
   */
  renderDate(date) {
    if (_.isEmpty(date)) return 'N/A';
    return <TimeAgo date={toUTCDate(date)} />;
  }

  renderApprovalCardComponentPlugins(user, request) {
    const pluginRegistry = this.props.pluginRegistry;
    const plugins = pluginRegistry.getPluginsWithMethod('approval-card-components', 'getApprovalCardComponents');

    const components = _.flatMap(plugins, plugin => plugin.getApprovalCardComponents({ user, request }));

    return <>{components}</>;
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(PendingCard, {
  comment: observable,
  loading: observable,
  pendingSelection: observable,
  transformPlugins: observable,
  handleTypedComment: action,
  handleConfirmation: action,
  handleRequestSelection: action,
  handleApprovalDelete: action,
  appContext: computed,
});

export default inject('app', 'usersStore', 'approvalsStore', 'pluginRegistry')(withRouter(observer(PendingCard)));
