/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import React from 'react';
import { observable, runInAction, decorate, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Table, Segment, Header, Icon } from 'semantic-ui-react';

import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import { isStoreError, isStoreLoading, isStoreEmpty } from '@aws-ee/base-ui/dist/models/BaseStore';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import StructuredDataTableDetail from './StructuredDataTableDetail';
import formatDateTime from '../helpers/DateTimeFormat';

// expected props
// - study
class StructuredDataTables extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.structuredDataTablesStore = props.study.getStructuredDataTableStore();
    });
  }

  get study() {
    return this.props.study;
  }

  componentDidMount() {
    swallowError(this.structuredDataTablesStore.load());
    this.structuredDataTablesStore.startHeartbeat();
  }

  componentWillUnmount() {
    this.structuredDataTablesStore.stopHeartbeat();
  }

  render() {
    const store = this.structuredDataTablesStore;
    // Render loading, error, or tab content
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="m0" />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={1} />;
    } else if (isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else {
      content = this.renderTable();
    }

    return content;
  }

  renderTable() {
    return (
      <>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Records</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.structuredDataTablesStore.structuredDataTables.map(table => (
              <Table.Row key={table.id}>
                <Table.Cell>{table.name}</Table.Cell>
                <Table.Cell>{table.recordCount}</Table.Cell>
                <Table.Cell>{table.createdBy.username}</Table.Cell>
                <Table.Cell>{formatDateTime(table.createdAt)}</Table.Cell>
                <Table.Cell>
                  <StructuredDataTableDetail
                    study={this.study}
                    structuredDataTableStore={this.structuredDataTablesStore.getStructuredDataTableStore(table.id)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }

  renderEmpty() {
    return (
      <Segment placeholder className="mt0">
        <Header icon className="color-grey">
          <Icon name="file outline" />
          No tables
          <Header.Subheader>No tables are uploaded yet for this dataset</Header.Subheader>
        </Header>
      </Segment>
    );
  }
}

decorate(StructuredDataTables, {
  structuredDataTablesStore: observable,
  study: computed,
});

export default inject('study')(observer(StructuredDataTables));
