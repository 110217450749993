/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { runInAction } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { isStoreLoading, isStoreNew } from '@aws-ee/base-ui/dist/models/BaseStore';
import PendingCard from './PendingCard';

class PendingList extends React.Component {
  constructor(props) {
    super(props);

    runInAction(() => {
      this.selection = { type: 'pending', payload: {} }; // type can be: pending, study
    });
  }

  render() {
    const store = this.props.approvalsStore;
    if (isStoreNew(store) || isStoreLoading(store)) return null;

    return this.renderPendingSection();
  }

  renderPendingSection() {
    const { approvalsStore: store, getApprovalLabel } = this.props;
    const userTypesMap = store.groupByUsersAndTypes();
    const pendingCount = _.size(userTypesMap);

    if (pendingCount === 0) return <div />;

    return (
      <>
        {_.map(userTypesMap, (userType, username) => (
          <PendingCard key={username} userType={userType} getApprovalLabel={getApprovalLabel} />
        ))}
      </>
    );
  }
}

export default inject('approvalsStore')(withRouter(observer(PendingList)));
