/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import { detach, types, applySnapshot } from 'mobx-state-tree';

import { StudyFilesStore } from './StudyFilesStore';
import { StudyPermissionsStore } from './StudyPermissionsStore';
import { StructuredDataTablesStore } from './StructuredDataTablesStore';
import { categories } from './categories';

// ==================================================================
// Study
// ==================================================================
const Study = types
  .model('Study', {
    id: types.identifier,
    rev: types.maybe(types.number),
    name: '',
    category: '',
    studyType: '',
    projectId: types.array(types.string, []),
    access: types.maybe(types.string),
    resources: types.optional(types.array(types.model({ arn: types.string })), []),
    description: types.maybeNull(types.string),
    uploadLocationEnabled: false,
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    filesStore: types.maybe(StudyFilesStore),
    permissionsStore: types.maybe(StudyPermissionsStore),
    structuredDataTablesStore: types.maybe(StructuredDataTablesStore),
    createdByUsername: '',
    updatedByUsername: '',
  })
  .actions(self => ({
    setStudy(rawStudy) {
      // Need to detach these stores and restore them as new, because applying the
      // snapshot clears them out.
      const fileStore = self.fileStore ? detach(self.filesStore) : self.fileStore;
      const permissionsStore = self.permissionsStore ? detach(self.permissionsStore) : self.permissionsStore;
      const structuredDataTablesStore = self.structuredDataTablesStore
        ? detach(self.structuredDataTablesStore)
        : self.structuredDataTablesStor;

      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawStudy);

      self.fileStore = fileStore;
      self.permissionsStore = permissionsStore;
      self.structuredDataTablesStore = structuredDataTablesStore;
    },

    getFilesStore() {
      if (!self.filesStore) {
        self.filesStore = StudyFilesStore.create({ studyId: self.id });
      }
      return self.filesStore;
    },

    getPermissionsStore() {
      if (!self.permissionsStore) {
        self.permissionsStore = StudyPermissionsStore.create({ studyId: self.id });
      }
      return self.permissionsStore;
    },

    getStructuredDataTableStore() {
      if (!self.structuredDataTablesStore) {
        self.structuredDataTablesStore = StructuredDataTablesStore.create({ studyId: self.id });
      }
      return self.structuredDataTablesStore;
    },

    forceSetAccess(accessType) {
      self.access = accessType;
    },
  }))

  // eslint-disable-next-line no-unused-vars
  .views(self => ({
    // add view methods here
    get isOpenDataStudy() {
      return self.category === categories.openData.name; // TODO the backend should really send an id and not a name
    },

    get isOrganizationStudy() {
      return self.category === categories.organization.name; // TODO the backend should really send an id and not a name
    },
  }));

export { Study }; // eslint-disable-line import/prefer-default-export
