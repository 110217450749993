/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import { getParent } from 'mobx-state-tree';

import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { getApproval } from '../../helpers/api';

// ==================================================================
// ApprovalStore
// ==================================================================
const ApprovalStore = BaseStore.named('ApprovalStore')
  .props({
    id: '',
    tickPeriod: 300 * 1000, // 5 minutes
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      doLoad: async () => {
        const parent = getParent(self, 2);
        const rawApproval = await getApproval(self.id);
        parent.addApproval(rawApproval);
      },

      cleanup: () => {
        superCleanup();
      },
    };
  })

  .views(self => ({
    get approval() {
      const parent = getParent(self, 2);
      const w = parent.getApproval(self.id);
      return w;
    },
  }));

// Note: Do NOT register this in the global context, if you want to gain access
// to an instance use approvalsStore.getApprovalStore()
// eslint-disable-next-line import/prefer-default-export
export { ApprovalStore };
