/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import dvr from 'mobx-react-form/lib/validators/DVR';
import Validator from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const searchAuditLogsFormFields = {
  startTime: {
    label: 'Start Date/Time',
    placeholder: 'Start Date/Time',
    extra: {
      explain: 'Date/time must be in ISO format',
    },
    rules: 'regex:/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/',
  },
  endTime: {
    label: 'End Date/Time',
    placeholder: 'End Date/Time',
    extra: {
      explain: 'Date/time must be in ISO format',
    },
    rules: 'regex:/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/',
  },
  search: {
    label: 'Search the audit logs',
    fields: [
      {
        name: '0',
        fields: [
          {
            name: 'searchField',
            label: 'Field to search',
          },
          {
            name: 'searchValue',
            label: 'The value to search for',
            placeholder: 'Enter your search query',
            rules: 'regex:/^[A-Za-z0-9-_/ ]+$',
          },
        ],
      },
    ],
  },
};

function getSearchAuditLogsFormFields() {
  return searchAuditLogsFormFields;
}

function getSearchAuditLogsForm() {
  const plugins = { dvr: dvr(Validator) }; // , vjf: validator };
  return new MobxReactForm({ fields: searchAuditLogsFormFields }, { plugins });
}

export { getSearchAuditLogsForm, getSearchAuditLogsFormFields };
