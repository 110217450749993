/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Header, Divider } from 'semantic-ui-react';
import ReactTable from 'react-table';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import { isStoreError, isStoreLoading, isStoreReady } from '@aws-ee/base-ui/dist/models/BaseStore';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';

// eslint-disable-next-line react/prefer-stateless-function
class AuditTrail extends React.Component {
  componentDidMount() {
    const store = this.props.approvalsStore;
    swallowError(store.load());
    store.startHeartbeat();
  }

  componentWillUnmount() {
    const store = this.props.approvalsStore;
    store.stopHeartbeat();
  }

  renderMain() {
    const approvalsStore = this.props.approvalsStore;
    const usersStore = this.props.usersStore;
    const trail = approvalsStore.toAuditTrail();
    const size = _.size(trail);
    const pageSize = 10;
    const showPagination = size > pageSize;

    if (size === 0)
      return (
        <div>
          <Header as="h2" color="grey" className="mt4">
            Audit Trail
          </Header>
          <Divider />
          There is no audit trail to display
        </div>
      );

    return (
      <div>
        <Header as="h2" color="grey" className="mt4">
          Audit Trail
        </Header>
        <Divider />
        <ReactTable
          data={trail}
          className="-striped -highlight"
          showPagination={showPagination}
          defaultPageSize={pageSize}
          filterable
          columns={[
            {
              Header: 'Decision',
              accessor: 'status',
            },
            {
              Header: 'Requested By',
              id: 'username',
              accessor: d => {
                const u = usersStore.asUserObject({ uid: d.userIdentifier });
                return u ? u.longDisplayName : JSON.parse(d.userIdentifier).username;
              },
            },
            {
              Header: 'Type',
              accessor: 'type',
            },
            {
              Header: 'Item',
              accessor: 'itemId',
            },
            {
              Header: 'Requested Date',
              id: 'createdAt',
              accessor: d => this.props.renderDate(d.createdAt),
            },
            {
              Header: 'Decided By',
              id: 'updatedBy',
              accessor: d => {
                const u = usersStore.asUserObject({ uid: d.updatedBy });
                return u ? u.longDisplayName : d.updatedBy.username;
              },
            },
            {
              Header: 'Comment',
              accessor: 'comment',
              style: { whiteSpace: 'unset' },
            },
          ]}
        />
      </div>
    );
  }

  render() {
    const store = this.props.approvalsStore;
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3} />;
    } else if (isStoreReady(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return content;
  }
}

export default inject('usersStore', 'approvalsStore')(withRouter(observer(AuditTrail)));
