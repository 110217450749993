/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import React from 'react';
import _ from 'lodash';
import { isStoreLoading, isStoreReady } from '@aws-ee/base-ui/dist/models/BaseStore';
import DataExtractionApprovalsCardComponent from '../parts/DataExtractionApprovalsCardComponent';

// eslint-disable-next-line no-unused-vars
function getApprovalCardComponents({ user, request }) {
  return [<DataExtractionApprovalsCardComponent key="data-extraction" user={user} request={request} />];
}

async function getItemsCheckBoxTransform(appContext) {
  const scEnvironmentsStore = appContext.scEnvironmentsStore;
  if (!(isStoreReady(scEnvironmentsStore) || isStoreLoading(scEnvironmentsStore))) {
    await scEnvironmentsStore.load().catch(() => {});
  }

  const transform = ({ request, checkboxes }) =>
    checkboxes.map(checkbox => {
      if (checkbox.type !== 'data-extraction') {
        return checkbox;
      }

      const approval = _.find(request.approvals, ({ id }) => id === checkbox.id);
      const environmentId = approval.payload.environmentId;
      const environment = scEnvironmentsStore.getScEnvironment(environmentId);
      checkbox.text = `Data from workspace "${environment ? environment.name : environmentId}" that had access to ${
        checkbox.text
      }`;
      return checkbox;
    });

  return transform;
}

const plugin = {
  getApprovalCardComponents,
  getItemsCheckBoxTransform,
};

export default plugin;
