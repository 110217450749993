/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React from 'react';
import { decorate, action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Table, Message } from 'semantic-ui-react';
import JSONTreeComponent from 'react-json-tree/umd/react-json-tree';

// expected props
// - payload
// - status
// - result
class Payload extends React.Component {
  handleExpand = event => {
    event.preventDefault();
    event.stopPropagation();
    this.expanded = !this.expanded;
  };

  render() {
    const payload = this.props.payload || {};
    const status = this.props.status || 'ok';
    const result = this.props.result || {};
    const exception = result.exception;
    const message = exception ? JSON.stringify(exception) : result.reason || '';
    const isEmpty = _.isEmpty(payload);
    const theme = {
      scheme: 'default',
      base00: '#181818',
      base01: '#282828',
      base02: '#383838',
      base03: '#585858',
      base04: '#b8b8b8',
      base05: '#d8d8d8',
      base06: '#e8e8e8',
      base07: '#f8f8f8',
      base08: '#ab4642',
      base09: '#dc9656',
      base0A: '#f7ca88',
      base0B: '#a1b56c',
      base0C: '#86c1b9',
      base0D: '#7cafc2',
      base0E: '#ba8baf',
      base0F: '#a16946',
    };
    const invertTheme = true;
    if (isEmpty) return 'No payload';
    if (!this.expanded)
      return (
        <a className="cursor-pointer" onClick={this.handleExpand}>
          Show
        </a>
      );

    return (
      <div className="animated fadeIn">
        <Table basic="very" className="p1" celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Key</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(payload, (value, key) => (
              <Table.Row key={key} verticalAlign="top">
                <Table.Cell className="bold">{key}</Table.Cell>
                <Table.Cell className="breakout">
                  <JSONTreeComponent data={value} theme={theme} invertTheme={invertTheme} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {status === 'error' && <Message negative>{message}</Message>}
        <a className="cursor-pointer mt1 ml1" onClick={this.handleExpand}>
          Hide
        </a>
      </div>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(Payload, {
  expanded: observable,
  handleExpand: action,
});

export default observer(Payload);
