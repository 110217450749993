/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import { createForm } from '../../helpers/form';

const createStructuredDataTableFields = {
  // General fields
  id: {
    label: 'ID',
    placeholder: 'A unique ID used to reference the table',
    extra: {
      explain: 'Must be less than 100 characters long and only contain lowercase alphanumeric characters, "-", or "_"',
    },
    rules: ['required', 'string', 'between:1,100', 'regex:/^[a-z0-9-_]+$/'],
  },
  name: {
    label: 'Name',
    placeholder: 'A name for the table',
    rules: ['string', 'max:2048'],
  },
  description: {
    label: 'Description',
    placeholder: 'A description of the table',
    rules: ['string', 'max:8192'],
  },
};

const getCreateStructuredDataTableForm = () => {
  return createForm(createStructuredDataTableFields);
};

export { getCreateStructuredDataTableForm }; // eslint-disable-line import/prefer-default-export
