/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Icon, Label, Menu } from 'semantic-ui-react';
import { createLink } from '@aws-ee/base-ui/dist/helpers/routing';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';

class BadgedMenuItem extends React.Component {
  componentDidMount() {
    const store = this.props.store;
    swallowError(store.load());
    store.startHeartbeat();
  }

  componentWillUnmount() {
    const store = this.props.store;
    store.stopHeartbeat();
  }

  goto = pathname => () => {
    const location = this.props.location;
    const link = createLink({
      location,
      pathname,
    });

    this.props.history.push(link);
  };

  render() {
    const { url, icon, title } = this.props;
    const badgeValue = this.props.store.menuBadgeValue;
    const pathname = _.get(this.props.location, 'pathname', '');
    const is = value => _.startsWith(pathname, value);

    return (
      <Menu.Item key={url} active={is(url)} onClick={is(url) ? undefined : this.goto(url)}>
        <Icon name={icon} size="mini" />
        <span className="fs-7">{title}</span>
        {badgeValue > 0 && (
          <Label color="red" circular attached="top right" size="mini" className="mt2 mr2">
            {badgeValue}
          </Label>
        )}
      </Menu.Item>
    );
  }
}

export default withRouter(observer(BadgedMenuItem));
