/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import React, { Component, createRef } from 'react';
import { Button, Icon, Input, TextArea, Form } from 'semantic-ui-react';
import { decorate, action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';

import PropTypes from 'prop-types';

const views = {
  input: 'input',
  label: 'label',
};

class EditableLabel extends Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.view = views.label;
      this.value = '';
      this.previous = '';
      this.processing = false;
      this.dirty = false;

      this.inputRef = createRef();
      this.handleKeyUp = this.handleKeyUp.bind(this);
    });
  }

  async componentDidMount() {
    const { initialValue } = this.props;
    const { value, previous } = this;

    if (!value) {
      await this.setValue(initialValue);
    }

    if (!previous) {
      await this.setPrevious(initialValue);
    }
  }

  componentDidUpdate() {
    const { view } = this;

    if (view === views.input) {
      this.inputRef.current.focus();
    }
  }

  setDirty = action(dirty => {
    this.dirty = dirty;
  });

  setView = action(view => {
    this.view = view;
  });

  setPrevious = action(previous => {
    this.previous = previous;
  });

  setValue = action(value => {
    this.value = value;
  });

  setProcessingStatus = action(processing => {
    this.processing = processing;
  });

  async cancel() {
    this.setDirty(false);
    await this.setValue(this.previous);
    this.setView(views.label);
  }

  async submit(value) {
    this.setProcessingStatus(true);
    // First attempt to call save callback
    const returnFromSave = await this.props.save(value);

    // save call was successful then update the component state values
    this.setValue(value);
    this.setPrevious(value);
    this.setView(views.label);
    this.setProcessingStatus(false);
    this.setDirty(false);

    return returnFromSave;
  }

  async handleKeyUp(e) {
    if (!this.props.enableKeys) {
      return;
    }

    // We need this otherwise React squawks at accessing the event in an async function
    e.persist();

    if (e.key === 'Escape') {
      await this.cancel();
    } else if (e.key === 'Enter') {
      await this.submit(e.target.value);
    }
  }

  handleOnChange = newValue => {
    this.setDirty(true);
    this.setValue(newValue);
  };

  renderInput() {
    const { value, processing } = this;
    const { inputClass } = this.props;

    let input;
    if (this.props.multiline) {
      // As per https://react.semantic-ui.com/addons/text-area/#types-text-area
      // Need to wrap TextArea with Form for correctly styling
      input = (
        <Form>
          <TextArea
            ref={this.inputRef}
            disabled={processing}
            placeholder={value}
            value={value}
            onChange={e => this.handleOnChange(e.target.value)}
            onKeyUp={this.handleKeyUp}
            className={inputClass !== undefined ? inputClass : ''}
          />
        </Form>
      );
    } else {
      input = (
        <Input
          fluid
          ref={this.inputRef}
          disabled={processing}
          placeholder={value}
          value={value}
          onChange={e => this.handleOnChange(e.target.value)}
          onKeyUp={this.handleKeyUp}
          className={inputClass !== undefined ? inputClass : ''}
        />
      );
    }
    return (
      <div>
        {input}
        <div className="mt2 clearfix">
          <Button
            floated="right"
            color="blue"
            icon
            disabled={processing}
            className="ml2"
            size="mini"
            onClick={() => this.submit(value)}
          >
            Save
          </Button>
          <Button floated="right" disabled={processing} size="mini" onClick={() => this.cancel()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  renderLabel() {
    const { value } = this;
    const { labelClass, initialValue } = this.props;
    return (
      <div>
        <span className={labelClass !== undefined ? labelClass : ''}>{this.dirty ? value : initialValue}</span>
        <Icon name="pencil" className="ml1 cursor-pointer" color="grey" onClick={() => this.setView(views.input)} />
      </div>
    );
  }

  render() {
    const { view } = this;
    if (view === views.label) {
      return this.renderLabel();
    }
    return this.renderInput();
  }
}

EditableLabel.defaultProps = {
  labelClass: '',
  inputClass: '',
  enableKeys: true,
  multiline: false,
};

EditableLabel.propTypes = {
  initialValue: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  enableKeys: PropTypes.bool,
  multiline: PropTypes.bool,
};

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(EditableLabel, {
  view: observable,
  value: observable,
  previous: observable,
  dirty: observable,
  processing: observable,
});

export default observer(EditableLabel);
