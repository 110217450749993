/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, decorate, observable, runInAction } from 'mobx';
import { Button, Header, Modal } from 'semantic-ui-react';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';

import FileUpload from '../files/FileUpload';
import { uploadedFiles, getPresignedStudyUploadRequests } from '../../helpers/api';
import upload from '../../helpers/xhr-upload';

// expected props
// - dataModel (either a study or a structured data table)
// - floated (optional float value of trigger button, but when set must be 'left' or 'right')
class UploadStudyFiles extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.modalOpen = false;
    });
    this.fileUploadHandler = this.fileUploadHandler.bind(this);
    this.uploaded = false;
  }

  get studyId() {
    // StudyId field is present if dataModel is a StructuredDataTable.
    return this.props.dataModel.studyId ? this.props.dataModel.studyId : this.props.dataModel.id;
  }

  get structuredDataTableId() {
    // StudyId field is present if dataModel is a StructuredDataTable.
    return this.props.dataModel.studyId ? this.props.dataModel.id : null;
  }

  async fileUploadHandler(fileUpload, totalFilesInGroup) {
    const file = fileUpload.getFile();
    if (!file) {
      throw new Error('No file');
    }

    // Get presigned POST request
    let uploadRequest;
    try {
      const presignResult = await getPresignedStudyUploadRequests(
        fileUpload.name,
        fileUpload.size,
        totalFilesInGroup,
        this.studyId,
        this.structuredDataTableId,
      );
      uploadRequest = presignResult[fileUpload.name];
    } catch (error) {
      const errMessage = error;
      console.error(`${errMessage}:`, error);
      // displayError(error);
      throw new Error(errMessage);
    }

    if (!uploadRequest) {
      throw new Error('Failed to obtain presigned request');
    }

    // Upload file
    const uploadHandle = upload(file, uploadRequest.url, uploadRequest.fields);
    fileUpload.setCancel(uploadHandle.cancel);
    uploadHandle.onProgress(fileUpload.updateProgress);

    try {
      await uploadHandle.done;
      this.uploaded = true;
    } catch (error) {
      const errMessage = 'Error encountered while uploading file';
      console.error(`${errMessage}:`, error);
      throw new Error(errMessage);
    }
  }

  async closeHandler() {
    runInAction(() => {
      this.modalOpen = false;
    });

    if (!this.uploaded) {
      return;
    }

    this.uploaded = false;

    try {
      await uploadedFiles(this.studyId, this.structuredDataTableId);

      if (this.structuredDataTableId) {
        runInAction(() => {
          this.props.dataModel.setSchemaDiscoveryStatus('In Progress');
          this.props.dataModel.setSchemaDiscoveryUrl('');
        });
      }

      // Reload the files store so that it can get the list of files including the
      // uploaded files. One second delay is needed to minimize inconsistency due
      // to the eventual consistency model of S3.
      setTimeout(() => {
        this.props.dataModel.getFilesStore().load();
      }, 1000);
    } catch (error) {
      displayError(error);
    }
  }

  render() {
    return (
      <Modal closeIcon trigger={this.renderTrigger()} open={this.modalOpen} onClose={() => this.closeHandler()}>
        <div className="mt2 animated fadeIn">
          <Header as="h3" icon textAlign="center" className="mt3" color="grey">
            Upload Dataset Files
          </Header>
          <div className="mx3 animated fadeIn">
            <FileUpload resourceId={this.props.dataModel.id} fileUploadHandler={this.fileUploadHandler} />
          </div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    const opts = {};

    if (this.props.floated) {
      opts.floated = this.props.floated;
    }

    return (
      <Button
        {...opts}
        color="blue"
        basic
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Upload Files
      </Button>
    );
  }
}

decorate(UploadStudyFiles, {
  modalOpen: observable,
});

export default inject('dataModel')(observer(UploadStudyFiles));
