/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import { types, applySnapshot } from 'mobx-state-tree';

// ==================================================================
// Approval
// ==================================================================
const Approval = types
  .model('Approval', {
    id: types.identifier,
    userIdentifier: types.string,
    rev: types.maybe(types.number),
    itemId: '',
    type: '',
    payload: types.frozen(),
    comment: types.optional(types.string, ''),
    status: types.optional(types.string, 'pending'),
    access: types.optional(types.string, ''),
    expiresAt: '',
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
  })
  .actions(self => ({
    setApproval(rawApproval) {
      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawApproval);
    },
  }));

// eslint-disable-next-line import/prefer-default-export
export { Approval };
