/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';
import uuid from 'uuid/v4';

import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { consolidateToMap } from '@aws-ee/base-ui/dist/helpers/utils';
import { deleteApproval, getApprovals, putApproval, updateApproval } from '../../helpers/api';
import { Approval } from './Approval';
import { ApprovalStore } from './ApprovalStore';

// ==================================================================
// ApprovalsStore
// ==================================================================
const ApprovalsStore = BaseStore.named('ApprovalsStore')
  .props({
    approvals: types.optional(types.map(Approval), {}),
    approvalStores: types.optional(types.map(ApprovalStore), {}),
    tickPeriod: 5 * 1000, // 5 seconds
  })

  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const approvals = await getApprovals();
        // We try to preserve existing approval data and merge the new data instead
        // We could have used self.approvals.replace(), but it will do clear() then merge()
        self.runInAction(() => {
          consolidateToMap(self.approvals, approvals, (exiting, newItem) => {
            exiting.setApproval(newItem);
          });
        });
      },

      // shouldHeartbeat: () => {
      //   // TODO: what is getEnv()? Why do we need this function?
      //   // const app = getEnv(self).app;
      //   // return app.userAuthenticated;
      // },

      clear: () => {
        self.requests.clear();
        superCleanup();
      },

      async approve(id, comment) {
        const previous = self.approvals.get(id);
        const approval = await updateApproval(id, { id, comment, status: 'approved' });
        self.runInAction(() => {
          previous.setApproval(approval);
        });
        return approval;
      },

      async decline(id, comment) {
        const previous = self.approvals.get(id);
        const approval = await updateApproval(id, { id, comment, status: 'declined' });
        self.runInAction(() => {
          previous.setApproval(approval);
        });
        return approval;
      },

      async delete(id) {
        const result = await deleteApproval(id);
        self.runInAction(() => {
          self.approvals.delete(id);
        });
        return result;
      },

      async addApproval(rawApproval) {
        const id = rawApproval.id;
        const previous = self.approvals.get(id);

        if (!previous) {
          const approval = await putApproval(rawApproval);
          self.runInAction(() => {
            self.approvals.put(approval);
          });
        } else {
          previous.setApproval(rawApproval);
        }
      },

      async requestApproval({ itemId, type }) {
        const id = uuid();
        const rawApproval = {
          id,
          itemId,
          type,
        };

        await self.addApproval(rawApproval);

        return self.getApproval(id);
      },

      getApprovalStore: id => {
        let entry = self.approvalStores.get(id);
        if (!entry) {
          // Lazily create the store
          self.approvalStores.set(id, ApprovalStore.create({ id }));
          entry = self.approvalStores.get(id);
        }

        return entry;
      },

      getApproval: id => {
        return self.approvals.get(id);
      },

      cleanup: () => {
        self.approvals.clear();
        superCleanup();
      },
    };
  })

  .views(self => ({
    countRequests: (status = 'pending') => {
      return self.list.filter(approval => approval.status === status && approval.access === 'admin').length;
    },

    countUniqueUsers: (status = 'pending') => {
      return _.uniqBy(
        self.list.filter(approval => approval.status === status && approval.access === 'admin'),
        'username',
      ).length;
    },

    get menuBadgeValue() {
      return _.size(self.groupByUsersAndTypes());
    },

    get empty() {
      return self.approvals.size === 0;
    },

    get total() {
      return self.approvals.size;
    },

    get list() {
      const result = [];
      self.approvals.forEach(approval => result.push(approval));

      return _.reverse(_.sortBy(result, ['createdAt', 'id']));
    },

    /**
     * Get all approval requests with a given status, grouped by userIdentifier and type.
     *
     * @param status the requested status of the approval request
     *
     * @returns a map of approval requests for the given status, keyed by userIdentifier and type
     */
    groupByUsersAndTypes: (status = 'pending') => {
      const userTypesMap = {};

      self.list
        .filter(approval => approval.status === status && approval.access === 'admin')
        .forEach(request => {
          const userTypeIdentifier = `${request.userIdentifier}|${request.type}`;
          const userType = userTypesMap[userTypeIdentifier] || {};
          userType.id = request.id;
          userType.userIdentifier = request.userIdentifier;
          userType.type = request.type;
          userType.approvals = userType.approvals || [];
          userType.createdAt = request.createdAt;
          userType.createdBy = request.createdBy;
          userType.approvals.push(request);
          userTypesMap[userTypeIdentifier] = userType;
        });

      return userTypesMap;
    },

    /**
     * Get all approval requests for a given user, filtered by type, grouped by itemId.
     *
     * @param status the requested userIdentifier
     *
     * @returns a map of approval requests for the given user, keyed by type
     */
    forUserByItem: ({ userIdentifier, type }) => {
      const itemsMap = new Map();

      self.list
        .filter(approval => approval.userIdentifier === userIdentifier && (type ? approval.type === type : true))
        .sort((a, b) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return aDate.getTime() - bDate.getTime();
        })
        .forEach(request => {
          // Originally this code gave all approvals, but for simplicity just give the latest
          // const item = itemsMap.get(request.itemId) || {};
          const item = {};
          item.id = request.itemId;
          item.approval = request;
          itemsMap.set(request.itemId, item);
        });

      return itemsMap;
    },

    get dropdownOptions() {
      const result = [];
      // converting map self.approvals to result array
      self.approvals.forEach(approval => {
        const res = {};
        res.key = approval.id;
        res.value = approval.id;
        res.text = approval.id;
        result.push(res);
      });
      return result;
    },

    hasApproval(id) {
      return self.approvals.has(id);
    },

    getApproval(id) {
      return self.approvals.get(id);
    },
    // only returns 'approved' and 'denied' requests
    toAuditTrail: () => {
      const arr = _.filter(self.list, approval => approval.status !== 'pending' && approval.status !== 'expired');
      return _.reverse(_.sortBy(arr, ['updatedAt', 'createdAt']));
    },
  }));

function registerContextItems(appContext) {
  appContext.approvalsStore = ApprovalsStore.create({}, appContext);
}

export { ApprovalsStore, registerContextItems };
