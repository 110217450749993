/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { StructuredDataTable } from './StructuredDataTable';
import { createStructuredDataTable, listStructuredDataTables } from '../../helpers/api';
import { StructuredDataTableStore } from './StructuredDataTableStore';

// ==================================================================
// StructuredDataTablesStore
// ==================================================================
const StructuredDataTablesStore = BaseStore.named('StructuredDataTablesStore')
  .props({
    studyId: types.identifier,
    structuredDataTables: types.array(StructuredDataTable),
    structuredDataTableStores: types.optional(types.map(StructuredDataTableStore), {}),
    tickPeriod: 300 * 1000, // 5 minutes
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async createStructuredDataTable(structuredDataTable) {
        await createStructuredDataTable(self.studyId, structuredDataTable);
      },
      async doLoad() {
        let tables = await listStructuredDataTables(self.studyId);

        // Determine which tables were added or removed
        const comparator = (tableA, tableB) => tableA.id === tableB.id;
        const removed = _.differenceWith(self.structuredDataTables, tables, comparator);
        const added = _.differenceWith(tables, self.structuredDataTables, comparator);

        // Only update store when needed to avoid unnecessary re-rendering
        if (removed.length !== 0 || added.length !== 0) {
          // Sort files by name and cast lastModified as Date()
          tables = tables
            .sort((tableA, tableB) => tableA.name.localeCompare(tableB.name))
            .map(table => ({ ...table, studyId: self.studyId }));

          // Update store
          self.runInAction(() => {
            self.structuredDataTables.replace(tables);
          });
        }
      },
      addStructuredDataTable(rawData) {
        const index = _.findIndex(self.structuredDataTables, ['id', rawData.id]);

        if (index === -1) {
          self.runInAction(() => {
            self.structuredDataTables.push([rawData]);
          });
        } else {
          self.structuredDataTables[index].setStructuredDataTable(rawData);
        }
      },
      getStructuredDataTableStore(id) {
        let store = self.structuredDataTableStores.get(id);

        if (!store) {
          self.structuredDataTableStores.set(id, StructuredDataTableStore.create({ id }));
          store = self.structuredDataTableStores.get(id);
        }

        return store;
      },
      getStructuredDataTable(id) {
        const index = _.findIndex(self.structuredDataTables, ['id', id]);

        return self.structuredDataTables[index];
      },
      cleanup: () => {
        self.structuredDataTables.clear();
        superCleanup();
      },
    };
  })
  .views(self => ({
    get empty() {
      return self.structuredDataTables.length === 0;
    },
  }));

export { StructuredDataTablesStore }; // eslint-disable-line import/prefer-default-export
