/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

/* eslint-disable max-classes-per-file */
import _ from 'lodash';
import React from 'react';

import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import { inject, observer } from 'mobx-react';
import { action, decorate, observable, runInAction, computed } from 'mobx';
import { Button, Header, Table, Modal, Tab } from 'semantic-ui-react';
import StructuredDataTableColumns from './StructuredDataTableColumns';

import StudyFilesTable from './StudyFilesTable';
import UploadStudyFiles from './UploadStudyFiles';

// This component is used with the TabPane to replace the default Segment wrapper since
// we don't want to display the border.
// eslint-disable-next-line react/prefer-stateless-function
class TabPaneWrapper extends React.Component {
  render() {
    return <>{this.props.children}</>;
  }
}

// expected props
// - study
// - structuredDataTableStore
class StructuredDataTableDetail extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.cleanModal();
    });
  }

  componentDidMount() {
    this.props.structuredDataTableStore.startHeartbeat();
  }

  componentWillUnmount() {
    this.props.structuredDataTableStore.stopHeartbeat();
  }

  get study() {
    return this.props.study;
  }

  get structuredDataTable() {
    return this.props.structuredDataTableStore.structuredDataTable;
  }

  cleanModal = () => {
    runInAction(() => {
      this.modalOpen = false;
    });
  };

  render() {
    return (
      <Modal
        closeIcon
        trigger={this.renderTrigger()}
        open={this.modalOpen}
        onClose={action(() => {
          this.modalOpen = false;
        })}
      >
        <div className="mt3 mx3 mb3">
          {this.renderHeader()}
          {this.renderTableInfo()}
          {this.study.uploadLocationEnabled && this.study.access === 'admin' && (
            <UploadStudyFiles className="mt3 mb3" dataModel={this.structuredDataTable} />
          )}
          {this.renderTabs()}
        </div>
      </Modal>
    );
  }

  renderTableInfo() {
    return (
      <>
        <Table definition celled className="mb3">
          <Table.Body>
            {this.renderTableInfoRow('ID', this.structuredDataTable.id)}
            {this.renderTableInfoRow('Name', this.structuredDataTable.name)}
            {this.renderTableInfoRow('Description', this.structuredDataTable.description)}
            {this.renderTableInfoRow(
              'Schema Discovery Status',
              this.renderSchemaDiscovery(
                this.structuredDataTable.schemaDiscoveryStatus,
                this.structuredDataTable.schemaDiscoveryUrl,
              ),
            )}
          </Table.Body>
        </Table>
      </>
    );
  }

  renderSchemaDiscovery(status, url) {
    if (url) {
      return <a href={url}>{status}</a>;
    }

    return status;
  }

  renderTableInfoRow(name, value) {
    return (
      <Table.Row className="mb4">
        <Table.Cell width={4} className="bold">
          {name}
        </Table.Cell>
        <Table.Cell>{_.isEmpty(value) ? 'Not Provided' : value}</Table.Cell>
      </Table.Row>
    );
  }

  renderTabs() {
    const panes = [this.renderColumnsTab(), this.renderFilesTab()];

    return (
      <div>
        {this.renderScrollableTableStyle()}
        <Tab
          className="mt3"
          style={{ height: '23rem' }}
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          renderActiveOnly
        />
      </div>
    );
  }

  renderColumnsTab() {
    return {
      menuItem: 'Columns',
      render: () => (
        <Tab.Pane attached={false} key="columns" as={TabPaneWrapper}>
          <StructuredDataTableColumns study={this.study} structuredDataTable={this.structuredDataTable} />
        </Tab.Pane>
      ),
    };
  }

  renderFilesTab() {
    return {
      menuItem: 'Files',
      render: () => (
        <Tab.Pane attached={false} key="files" as={TabPaneWrapper}>
          <StudyFilesTable dataModel={this.structuredDataTable} />
        </Tab.Pane>
      ),
    };
  }

  renderScrollableTableStyle() {
    return (
      // Adds a vertical scrollbar and a table body max height of 16rem
      // to the tables in the tab that have the CSS class 'scrollable'
      <style scoped>
        {`
          .scrollable tbody {
            display: block;
            max-height: 16rem;
            width: 100%;
            overflow-y: auto;
          }
          .scrollable thead, .scrollable tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed
          }
      `}
      </style>
    );
  }

  renderHeader() {
    return (
      <Header as="h3" icon textAlign="center" color="grey">
        {this.study.category} &gt; {this.structuredDataTable.name}
      </Header>
    );
  }

  renderTrigger() {
    return (
      <Button
        floated="right"
        color="blue"
        compact
        onClick={action(() => {
          this.modalOpen = true;
          // Refresh the content when dialog is opened.
          swallowError(this.props.structuredDataTableStore.load());
        })}
      >
        Detail
      </Button>
    );
  }
}

decorate(StructuredDataTableDetail, {
  modalOpen: observable,
  study: computed,
  structuredDataTable: computed,
  cleanModal: action,
});

export default inject('study', 'structuredDataTableStore')(observer(StructuredDataTableDetail));
