/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { decorate, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import {
  Header,
  Pagination,
  Table,
  Label,
  Progress,
  Segment,
  Button,
  Grid,
  Icon,
  Container,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import {
  isStoreLoading,
  isStoreReady,
  isStoreEmpty,
  isStoreNotEmpty,
  isStoreError,
  isStoreReloading,
} from '@aws-ee/base-ui/dist/models/BaseStore';
import { swallowError, niceNumber } from '@aws-ee/base-ui/dist/helpers/utils';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import Form from '@aws-ee/base-ui/dist/parts/helpers/fields/Form';
import FormInput from '@aws-ee/base-ui/dist/parts/helpers/fields/Input';
import ErrorPointer from '@aws-ee/base-ui/dist/parts/helpers/fields/ErrorPointer';
import Payload from './Payload';
import { getSearchAuditLogsForm } from '../../models/forms/SearchAuditLogsForm';

// expected props
// - auditStore (via injection)
class AuditPage extends React.Component {
  constructor(props) {
    super(props);
    this.form = getSearchAuditLogsForm();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const auditStore = this.getAuditStore();
    swallowError(
      auditStore.load({
        reload: true,
      }),
    );
  }

  get loading() {
    const auditStore = this.getAuditStore();
    return isStoreLoading(auditStore) || isStoreReloading(auditStore);
  }

  getAuditStore() {
    return this.props.auditStore;
  }

  handlePaginationChange = (e, { activePage }) => {
    if (this.loading) return; // we ignore page selection when the audit store is loading/reloading
    const auditStore = this.getAuditStore();
    const startTime = auditStore.startTime;
    const endTime = auditStore.endTime;
    const searchTerms = auditStore.searchTerms;
    swallowError(
      auditStore.load({
        page: activePage,
        reload: false, // don't reload
        startTime,
        endTime,
        searchTerms,
      }),
    );
  };

  handleFormError = () => {
    // Nothing to do here
  };

  handleSearchFormSubmission = form => {
    if (this.loading) return;
    const values = form.values();
    const auditStore = this.getAuditStore();
    const startTime = values.startTime;
    const endTime = values.endTime;
    const searchTerms = values.search;

    swallowError(
      auditStore.load({
        loadMore: true,
        clear: true,
        startTime,
        endTime,
        searchTerms,
      }),
    );
  };

  render() {
    const store = this.getAuditStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (this.loading || isStoreLoading(store)) {
      content = this.renderMain();
    } else if (isStoreReady(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3 mb4">
        {this.renderTitle()}
        {content}
      </Container>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="eye" className="align-top" />
          <Header.Content className="left-align">Audit Logs</Header.Content>
        </Header>
      </div>
    );
  }

  renderMain() {
    return (
      <div>
        {this.renderSearchForm()}
        <Segment.Group>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
          <Segment clearing>
            {this.renderTotal()}
            {this.renderMainResult()}
          </Segment>
          <Segment clearing secondary>
            {this.renderPagination()}
          </Segment>
        </Segment.Group>
      </div>
    );
  }

  renderPagination() {
    const auditStore = this.getAuditStore();
    const currentPage = auditStore.currentPage;
    const totalPages = auditStore.totalPages;
    if (totalPages < 2) return <div className="mb2" />;

    return (
      <Pagination
        activePage={currentPage}
        onPageChange={this.handlePaginationChange}
        totalPages={totalPages}
        floated="right"
      />
    );
  }

  renderTotal() {
    const count = this.getAuditStore().total;
    const niceCount = niceNumber(count);

    return (
      <Header as="h3" className="color-grey mt1 mb0 flex-auto">
        Loaded Entries :{' '}
        <Label circular size="large">
          {niceCount}
        </Label>
      </Header>
    );
  }

  renderMainResult() {
    const auditStore = this.getAuditStore();
    const loading = this.loading;
    let content = null;
    let progressBar = null;

    if (loading) {
      progressBar = <Progress percent={auditStore.progress} indicating />;
    }

    if (isStoreError(auditStore)) {
      content = <ErrorBox error={auditStore.error} />;
    } else if (loading) {
      content = this.renderResult();
    } else if (isStoreReady(auditStore) && isStoreEmpty(auditStore)) {
      content = this.renderEmpty();
    } else if (isStoreReady(auditStore) && isStoreNotEmpty(auditStore)) {
      content = this.renderResult();
    } else {
      content = null;
    }

    return (
      <div className="animated fadeIn mt3">
        {progressBar}
        {content}
      </div>
    );
  }

  renderEmpty() {
    return <div>No audit entries</div>;
  }

  renderSearchForm() {
    const form = this.form;
    const startTime = form.$('startTime');
    const endTime = form.$('endTime');
    const search = form.$('search');

    const fieldDropdownOptions = [
      {
        key: 'action',
        value: 'action',
        text: 'Action',
      },
      {
        key: 'actorUsername',
        value: 'actorUsername',
        text: 'Actor',
      },
      {
        key: 'message',
        value: 'message',
        text: 'Message',
      },
    ];

    return (
      <Segment clearing className="mb1">
        <Form form={form} onSuccess={this.handleSearchFormSubmission} onError={this.handleFormError}>
          {({ loading }) => (
            <>
              <Grid columns="two">
                <Grid.Column key="startTime">
                  <FormInput field={startTime} disabled={loading} className="mb1" />
                </Grid.Column>
                <Grid.Column key="endTime">
                  <FormInput field={endTime} disabled={loading} className="mb1" />
                </Grid.Column>
              </Grid>
              <Table basic="very" className="mb4 mt3">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: '14em' }}>Field to search</Table.HeaderCell>
                    <Table.HeaderCell>Value to search for</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right" style={{ width: '8em' }}>
                      <Button
                        color="blue"
                        icon
                        disabled={loading}
                        type="button"
                        onClick={() => {
                          search.add({
                            name: `${search.fields.size}`,
                            fields: [
                              {
                                name: 'searchField',
                                label: 'Field to search',
                              },
                              {
                                name: 'searchValue',
                                label: 'The value to search for',
                                placeholder: 'Enter your search query',
                                rules: 'regex:/^[A-Za-z0-9-_/ ]+$',
                              },
                            ],
                          });
                        }}
                      >
                        Add Query
                      </Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {search.map(searchFields => (
                    <Table.Row key={searchFields.path}>
                      {searchFields.map(f => (
                        <Table.Cell key={f.path}>
                          {f.key === 'searchField' ? (
                            <Dropdown
                              options={fieldDropdownOptions}
                              placeholder="Choose the field"
                              selection
                              onChange={(_e, { value }) => {
                                f.value = value;
                              }}
                            />
                          ) : (
                            <Input
                              className="field"
                              disabled={loading}
                              fluid
                              type="text"
                              autoFocus={false}
                              {..._.omit(f.bind(), ['label'])}
                            />
                          )}
                          <ErrorPointer field={f} />
                        </Table.Cell>
                      ))}
                      <Table.Cell verticalAlign="middle" textAlign="right">
                        <Button
                          size="small"
                          color="red"
                          icon
                          type="button"
                          disabled={loading}
                          onClick={() => {
                            search.del(searchFields.key);
                          }}
                        >
                          X
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <div>
                <Button floated="right" color="blue" icon disabled={loading} type="submit">
                  Search Logs
                </Button>
              </div>
            </>
          )}
        </Form>
      </Segment>
    );
  }

  renderResult() {
    const auditStore = this.getAuditStore();
    const result = auditStore.visibleRecords;
    const renderRow = (row, index) => {
      return (
        <Table.Row key={index} verticalAlign="top">
          <Table.Cell textAlign="center" collapsing>
            {row.getISODate}
          </Table.Cell>
          <Table.Cell textAlign="center" collapsing>
            {row.actorUsername}
          </Table.Cell>
          <Table.Cell textAlign="left" collapsing style={{ whiteSpace: 'normal' }}>
            {row.action}
          </Table.Cell>
          <Table.Cell textAlign="left" collapsing style={{ whiteSpace: 'normal' }}>
            {row.message}
          </Table.Cell>
          <Table.Cell textAlign="left" collapsing>
            <Payload payload={row.body} status={row.status} result={row.result} />
          </Table.Cell>
        </Table.Row>
      );
    };

    return (
      <Table striped stackable selectable celled size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" collapsing>
              Time
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" collapsing>
              Actor
            </Table.HeaderCell>
            <Table.HeaderCell collapsing>Action</Table.HeaderCell>
            <Table.HeaderCell collapsing>Message</Table.HeaderCell>
            <Table.HeaderCell>Payload</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{_.map(result, (row, index) => renderRow(row, index))}</Table.Body>
      </Table>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(AuditPage, {
  loading: computed,
  handlePaginationChange: action,
  handleLoadMoreRecords: action,
  handleSearchFormSubmission: action,
  handleSearchFormError: action,
});

export default inject('auditStore')(withRouter(observer(AuditPage)));
