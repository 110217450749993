/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import { types, detach, applySnapshot } from 'mobx-state-tree';

import { StudyFilesStore } from './StudyFilesStore';

import { StructuredDataTableColumnsStore } from './StructuredDataTableColumnsStore';

// ==================================================================
// Structured Data Table
// ==================================================================
const StructuredDataTable = types
  .model('StructuredDataTable', {
    id: types.identifier,
    studyId: '',
    rev: types.maybe(types.number),
    name: '',
    description: '',
    recordCount: 0,
    schemaDiscoveryStatus: '',
    schemaDiscoveryUrl: '',
    createdAt: '',
    createdBy: '',
    updatedAt: '',
    updatedBy: '',
    filesStore: types.maybe(StudyFilesStore),
    columnsStore: types.maybe(StructuredDataTableColumnsStore),
  })
  .actions(self => ({
    setSchemaDiscoveryStatus(status) {
      self.schemaDiscoveryStatus = status;
    },

    setSchemaDiscoveryUrl(url) {
      self.schemaDiscoveryUrl = url;
    },

    setStructuredDataTable(rawData) {
      // Need to detach these stores and restore them as new, because applying the
      // snapshot clears them out.
      const fileStore = self.fileStore ? detach(self.filesStore) : self.fileStore;
      const columnsStore = self.columnsStore ? detach(self.columnsStore) : self.columnsStore;

      // Note: if you have partial data vs full data, you need to replace the applySnapshot() with
      // the appropriate logic
      applySnapshot(self, rawData);

      self.fileStore = fileStore;
      self.columnsStore = columnsStore;
    },
    getFilesStore() {
      if (!self.filesStore) {
        self.filesStore = StudyFilesStore.create({ studyId: self.studyId, structuredDataTableId: self.id });
      }
      return self.filesStore;
    },
    getColumnsStore() {
      if (!self.columnsStore) {
        self.columnsStore = StructuredDataTableColumnsStore.create({
          studyId: self.studyId,
          structuredDataTableId: self.id,
        });
      }
      return self.columnsStore;
    },
  }));

export { StructuredDataTable }; // eslint-disable-line import/prefer-default-export
