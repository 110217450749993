/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import baseAppContextItemsPlugin from '@aws-ee/base-ui/dist/plugins/app-context-items-plugin';
import baseInitializationPlugin from '@aws-ee/base-ui/dist/plugins/initialization-plugin';
import baseAuthenticationPlugin from '@aws-ee/base-ui/dist/plugins/authentication-plugin';
import baseAppComponentPlugin from '@aws-ee/base-ui/dist/plugins/app-component-plugin';
import baseMenuItemsPlugin from '@aws-ee/base-ui/dist/plugins/menu-items-plugin';
import baseRoutesPlugin from '@aws-ee/base-ui/dist/plugins/routes-plugin';
import workflowAppContextItemsPlugin from '@aws-ee/base-workflow-ui/dist/plugins/app-context-items-plugin';
import workflowMenuItemsPlugin from '@aws-ee/base-workflow-ui/dist/plugins/menu-items-plugin';
import workflowRoutesPlugin from '@aws-ee/base-workflow-ui/dist/plugins/routes-plugin';
import raasAppContextItemsPlugin from '@aws-ee/dundee-raas-ui/dist/plugins/app-context-items-plugin';
import raasInitializationPlugin from '@aws-ee/dundee-raas-ui/dist/plugins/initialization-plugin';
import raasAppComponentPlugin from '@aws-ee/dundee-raas-ui/dist/plugins/app-component-plugin';
import raasMenuItemsPlugin from '@aws-ee/dundee-raas-ui/dist/plugins/menu-items-plugin';
import raasRoutesPlugin from '@aws-ee/dundee-raas-ui/dist/plugins/routes-plugin';
import raasEnvTypeMgmtPlugin from '@aws-ee/dundee-raas-ui/dist/plugins/env-type-mgmt-plugin';
import raasAppStreamAppContextItemsPlugin from '@aws-ee/base-raas-appstream-ui/dist/plugins/app-context-items-plugin';
import raasAppStreamRoutesPlugin from '@aws-ee/base-raas-appstream-ui/dist/plugins/routes-plugin';

import approvalsMenuItemsPlugin from '@aws-ee/approvals-ui/dist/plugins/menu-items-plugin';
import approvalsRoutesPlugin from '@aws-ee/approvals-ui/dist/plugins/routes-plugin';
import approvalsAppContextItemsPlugin from '@aws-ee/approvals-ui/dist/plugins/app-context-items-plugin';

import auditMenuItemsPlugin from '@aws-ee/audit-qldb-ui/dist/plugins/menu-items-plugin';
import auditRoutesPlugin from '@aws-ee/audit-qldb-ui/dist/plugins/routes-plugin';
import auditAppContextItemsPlugin from '@aws-ee/audit-qldb-ui/dist/plugins/app-context-items-plugin';

// import dataExtractionWorkspaceDetailsPlugin from '@aws-ee/data-extraction-ui/dist/plugins/workspace-details-plugin';
// import dataExtractionAppContextItemsPlugin from '@aws-ee/data-extraction-ui/dist/plugins/app-context-items-plugin';
// import dataExtractionStudyRowPlugin from '@aws-ee/data-extraction-ui/dist/plugins/study-row-plugin';

import envMgmtMenuItemsPlugin from '@aws-ee/dundee-environment-type-mgmt-ui/dist/plugins/menu-items-plugin';
import envMgmtRoutesPlugin from '@aws-ee/dundee-environment-type-mgmt-ui/dist/plugins/routes-plugin';
import envMgmtAppContextItemsPlugin from '@aws-ee/dundee-environment-type-mgmt-ui/dist/plugins/app-context-items-plugin';

import keyPairAppContextItemsPlugin from '@aws-ee/key-pair-mgmt-ui/dist/plugins/app-context-items-plugin';
import keyPairMenuItemsPlugin from '@aws-ee/key-pair-mgmt-ui/dist/plugins/menu-items-plugin';
import keyPairRoutesPlugin from '@aws-ee/key-pair-mgmt-ui/dist/plugins/routes-plugin';

import appContextItemsPlugin from './app-context-items-plugin';
import initializationPlugin from './initialization-plugin';
import menuItemsPlugin from './menu-items-plugin';
import routesPlugin from './routes-plugin';
import approvalCardPlugin from './approval-card-plugin';

// baseAppContextItemsPlugin registers app context items (such as base MobX stores etc) provided by the base addon
// baseInitializationPlugin registers the base initialization logic provided by the base ui addon
// baseMenuItemsPlugin registers menu items provided by the base addon
// baseRoutesPlugin registers base routes provided by the base addon
const extensionPoints = {
  'app-context-items': [
    baseAppContextItemsPlugin,
    workflowAppContextItemsPlugin,
    envMgmtAppContextItemsPlugin,
    keyPairAppContextItemsPlugin,
    raasAppContextItemsPlugin,
    auditAppContextItemsPlugin,
    // dataExtractionAppContextItemsPlugin,
    approvalsAppContextItemsPlugin,
    raasAppStreamAppContextItemsPlugin,
    appContextItemsPlugin,
  ],
  'initialization': [baseInitializationPlugin, raasInitializationPlugin, initializationPlugin],
  'authentication': [baseAuthenticationPlugin],
  'app-component': [baseAppComponentPlugin, raasAppComponentPlugin],
  'menu-items': [
    baseMenuItemsPlugin,
    workflowMenuItemsPlugin,
    envMgmtMenuItemsPlugin,
    keyPairMenuItemsPlugin,
    raasMenuItemsPlugin,
    // notificationsMenuItemsPlugin,
    auditMenuItemsPlugin,
    approvalsMenuItemsPlugin,
    auditMenuItemsPlugin,
    approvalsMenuItemsPlugin,
    menuItemsPlugin,
  ],
  'routes': [
    baseRoutesPlugin,
    workflowRoutesPlugin,
    envMgmtRoutesPlugin,
    keyPairRoutesPlugin,
    raasRoutesPlugin,
    auditRoutesPlugin,
    raasAppStreamRoutesPlugin,
    approvalsRoutesPlugin,
    routesPlugin,
  ],
  'env-type-management': [raasEnvTypeMgmtPlugin],
  // 'workspace-details-panes': [dataExtractionWorkspaceDetailsPlugin],
  'approval-card-components': [approvalCardPlugin],
  // 'study-row-components': [studyRowPlugin, dataExtractionStudyRowPlugin],
};

function getPlugins(extensionPoint) {
  return extensionPoints[extensionPoint];
}

const registry = {
  getPlugins,
};

export default registry;
