/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

import { types } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { StructuredDataTableColumn } from './StructuredDataTableColumn';
import { listStructuredDataTableColumns, updateStructuredDataTableColumn } from '../../helpers/api';

// ==================================================================
// StructuredDataTableColumnsStore
// ==================================================================
const StructuredDataTableColumnsStore = BaseStore.named('StructuredDataTableColumnsStore')
  .props({
    structuredDataTableId: types.identifier,
    studyId: '',
    columns: types.array(StructuredDataTableColumn),
    tickPeriod: 300 * 1000, // 5 minutes
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        let columns = await listStructuredDataTableColumns(self.studyId, self.structuredDataTableId);

        // Determine which columns were added or removed
        const comparator = (columnA, columnB) => columnA.id === columnB.id;
        const removed = _.differenceWith(self.columns, columns, comparator);
        const added = _.differenceWith(columns, self.columns, comparator);

        // Only update store when needed to avoid unnecessary re-rendering
        if (removed.length !== 0 || added.length !== 0) {
          // Sort files by name and cast lastModified as Date()
          columns = columns.sort((columnA, columnB) => columnA.id.localeCompare(columnB.id));
          // Update store
          self.runInAction(() => {
            self.columns.replace(columns);
          });
        }
      },
      async updateColumn(id, name, description) {
        const column = await updateStructuredDataTableColumn(self.studyId, {
          id,
          name,
          description,
          structuredDataTableId: self.structuredDataTableId,
        });

        const index = _.findIndex(self.columns, ['id', column.id]);

        if (index !== -1) {
          self.runInAction(() => {
            self.columns[index].name = column.name;
            self.columns[index].description = column.description;
          });
        }
      },
      cleanup: () => {
        self.columns.clear();
        superCleanup();
      },
    };
  })
  .views(self => ({
    get empty() {
      return self.columns.length === 0;
    },
  }));

export { StructuredDataTableColumnsStore }; // eslint-disable-line import/prefer-default-export
