/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';
import { searchAuditLogs } from '../../helpers/api';
import AuditEvent from './AuditEvent';

// ==================================================================
// AuditStore
// ==================================================================
const AuditStore = BaseStore.named('AuditStore')
  .props({
    currentPage: 1,
    totalPages: 1,
    loadedPages: 0,
    pageSize: 25,
    actor: types.maybe(types.string),
    total: 0,
    visibleRecords: types.optional(types.array(AuditEvent), []),
    loadedRecords: types.optional(types.array(AuditEvent), []),
    progress: 0,
    startTime: types.maybe(types.string),
    endTime: types.maybe(types.string),
    searchTerms: types.optional(types.frozen(), {}),
    nextPageToken: types.maybe(types.number),
  })

  .actions(self => {
    // save the base implementation of clear
    const superCleanup = self.cleanup;

    return {
      async doLoad({
        page = 1,
        pageSize = 25,
        reload = true,
        clear = false,
        startTime,
        endTime,
        searchTerms,
        customWhere,
      } = {}) {
        self.currentPage = page;
        self.pageSize = pageSize;
        self.startTime = startTime;
        self.endTime = endTime;
        self.searchTerms = searchTerms;
        self.customWhere = customWhere;

        if (reload || clear) {
          self.runInAction(() => {
            self.currentPage = 1;
            self.total = 0;
            self.loadedRecords = [];
            self.visibleRecords = [];
            self.progress = 0;
            self.loadedPages = 0;
            self.totalPages = 1;
            self.nextPageToken = undefined;
          });
        }

        const startTimeParam = startTime ? new Date(startTime).getTime() : Date.now();
        const endTimeParam = (() => {
          if (endTime) {
            return new Date(endTime).getTime();
          }
          // This will be invalid.
          return undefined;
        })();

        const searchWhereParam =
          searchTerms && searchTerms.length
            ? searchTerms
                .filter(({ searchField, searchValue }) => searchField && searchValue && searchValue.trim())
                .map(({ searchField, searchValue }) => {
                  const field = searchField;
                  const queryTerm = searchValue.trim();
                  return { field, operator: 'LIKE', queryTerm };
                })
            : undefined;

        const whereParam = customWhere || searchWhereParam;

        self.progress = 0;

        if (reload || self.currentPage > self.loadedPages) {
          self.visibleRecordsRendered = false;
          // eslint-disable-next-line no-await-in-loop
          const { results, nextPageToken } = await searchAuditLogs({
            startTime: startTimeParam,
            endTime: endTimeParam,
            where: whereParam,
            limit: pageSize,
            nextPageToken: self.nextPageToken,
          });
          self.runInAction(() => {
            self.total += results.length;
            self.loadedRecords.push(...results);
            // update progress bar
            self.progress = 100;
            self.loadedPages += 1;
            if (nextPageToken) {
              self.totalPages += 1;
              self.nextPageToken = nextPageToken;
            } else {
              self.nextPageToken = undefined;
            }
          });
        }

        self.runInAction(() => {
          // slice the records to show the current page
          self.visibleRecords.replace(
            _.cloneDeep(
              self.loadedRecords.slice(self.pageSize * (self.currentPage - 1), self.pageSize * self.currentPage),
            ),
          );
        });
      },

      cleanup: () => {
        superCleanup();
        self.currentPage = 1;
        self.total = 0;
        self.loadedRecords = [];
        self.visibleRecords = [];
        self.progress = 0;
      },
    };
  })

  .views(self => ({
    get empty() {
      return self.loadedRecords.length === 0;
    },
  }));

function registerContextItems(appContext) {
  appContext.auditStore = AuditStore.create({}, appContext);
}

export { AuditStore, registerContextItems };
