/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, decorate, observable, runInAction } from 'mobx';
import { Button, Header, Modal } from 'semantic-ui-react';
import { displayError, displaySuccess } from '@aws-ee/base-ui/dist/helpers/notification';

import { approvePendingStudy, rejectPendingStudy } from '../../helpers/api';
import { categories, getCategoryByName } from '../../models/studies/categories';

const modalTypes = {
  approve: {
    approve: true,
    content: 'Please confirm approving this study to an Organization Study',
    header: 'Approve Study',
  },
  reject: { approve: false, content: 'Please confirm rejecting this study', header: 'Reject Study' },
};

class AcceptRejectPendingStudy extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.modalOpen = false;
      this.processing = false;
    });

    this.modalType = modalTypes.approve;
    this.study = this.props.study;
  }

  getStudiesStore(studyCategoryId) {
    return this.props.studiesStoresMap[studyCategoryId];
  }

  async closeHandler() {
    runInAction(() => {
      this.processing = false;
      this.modalOpen = false;
    });
  }

  async handleModalConfirm() {
    runInAction(() => {
      this.processing = true;
    });

    try {
      if (this.modalType.approve) {
        await this.approveStudy();
        displaySuccess('Study has been approved');
      } else {
        await this.rejectStudy();
        displaySuccess('Study has been rejected');
      }
    } catch (error) {
      displayError(error);
    }

    this.closeHandler();
  }

  async approveStudy() {
    const studyId = this.study.id;

    const result = await approvePendingStudy(studyId);
    result.access = 'admin'; // Ugly! Needed for proper UI refresh
    this.refreshStudiesStore(result);
  }

  async rejectStudy() {
    const studyId = this.study.id;

    const result = await rejectPendingStudy(studyId);
    this.refreshStudiesStore(result);
  }

  refreshStudiesStore(rawStudy) {
    const sourceStudyStore = this.getStudiesStore(categories.pendingApproval.id);
    const destStudyStore = this.getStudiesStore(getCategoryByName(rawStudy.category).id);

    sourceStudyStore.deleteStudy(rawStudy);
    destStudyStore.addStudy(rawStudy);
  }

  render() {
    const modal = this.modalType;
    const study = this.study;

    return (
      <Modal
        closeIcon
        size="tiny"
        trigger={this.renderTrigger()}
        open={this.modalOpen}
        onClose={() => this.closeHandler()}
      >
        <Header content={modal.header} />
        <Modal.Content>
          <p>{modal.content}</p>
          <p>
            <b>{study.name}</b>
          </p>
          <p>
            Study: <b>{study.id}</b>
          </p>
          <p>
            Submitted by: <b>{study.createdByUsername}</b>
          </p>
        </Modal.Content>

        <Modal.Actions>
          <Button color="red" disabled={this.processing} onClick={() => this.closeHandler()}>
            Cancel
          </Button>
          <Button color="green" disabled={this.processing} onClick={() => this.handleModalConfirm()}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  renderTrigger() {
    const opts = {};

    if (this.props.floated) {
      opts.floated = this.props.floated;
    }

    return (
      <>
        <Button
          {...opts}
          color="green"
          onClick={action(() => {
            this.modalType = modalTypes.approve;
            this.modalOpen = true;
          })}
        >
          Approve
        </Button>
        <Button
          {...opts}
          color="red"
          basic
          onClick={action(() => {
            this.modalType = modalTypes.reject;
            this.modalOpen = true;
          })}
        >
          Reject
        </Button>
      </>
    );
  }
}

decorate(AcceptRejectPendingStudy, {
  modalOpen: observable,
  processing: observable,
});

export default inject('studiesStoresMap')(observer(AcceptRejectPendingStudy));
