/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import _ from 'lodash';
import React from 'react';
import { decorate, runInAction, observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Checkbox, Divider, Header, Label, Progress, Segment, Container, Icon } from 'semantic-ui-react';
import TimeAgo from 'react-timeago';

import { isStoreError, isStoreLoading, isStoreNew } from '@aws-ee/base-ui/dist/models/BaseStore';
import { swallowError, toUTCDate } from '@aws-ee/base-ui/dist/helpers/utils';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import PendingList from './PendingList';
import AuditTrail from './AuditTrail';

class Approvals extends React.Component {
  constructor(props) {
    super(props);

    // Standard Mobx mechanism for mutating state safely, in an action
    runInAction(() => {
      this.exactDate = false;
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const store = this.props.approvalsStore;
    swallowError(store.load());
    store.startHeartbeat();
  }

  componentWillUnmount() {
    const store = this.props.approvalsStore;
    store.stopHeartbeat();
  }

  handleToggleExactDate = (event, data) => {
    this.exactDate = data.checked;
  };

  renderDate(date) {
    if (_.isEmpty(date)) return 'N/A';
    const d = new Date(toUTCDate(date));
    if (this.exactDate) return d.toLocaleString();
    return <TimeAgo date={toUTCDate(date)} />;
  }

  render() {
    let content;
    const store = this.props.approvalsStore;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} />;
    } else if (isStoreLoading(store) || isStoreNew(store)) {
      content = <BasicProgressPlaceholder segmentCount={1} />;
    } else {
      content = (
        <div className="p3 animated fadeIn">
          {this.renderPendingSection()}
          {this.renderAuditTrail()}
        </div>
      );
    }

    return (
      <Container className="mt3">
        {this.renderTitle()}
        {content}
      </Container>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="list alternate outline" className="align-top" />
          <Header.Content className="left-align">Approvals</Header.Content>
        </Header>
      </div>
    );
  }

  renderPendingSection() {
    const { approvalsStore: store, getApprovalLabel = x => x } = this.props;
    const userTypesMap = store.groupByUsersAndTypes();
    const pendingCount = _.size(userTypesMap);

    return (
      <div>
        <Segment basic className="p0">
          <Header as="h2" color="grey" floated="left">
            Pending Approval
          </Header>
          <Header as="h2" color="grey" floated="right">
            <div className="flex justify-center items-center animated fadeIn">
              <Label className="mr1" basic size="mini" color="grey" pointing="right">
                Show Exact Dates
              </Label>
              <Checkbox toggle size="mini" checked={this.exactDate} onChange={this.handleToggleExactDate} />
            </div>
          </Header>
        </Segment>
        <Divider clearing />
        {pendingCount !== 0 ? (
          <PendingList getApprovalLabel={getApprovalLabel} />
        ) : (
          'There are no requests pending approval'
        )}
      </div>
    );
  }

  renderAuditTrail() {
    return <AuditTrail renderDate={this.renderDate} exactDate={this.exactDate} />;
  }

  renderProgress() {
    return (
      <Progress percent={100} active color="blue" className="m4 ml3">
        <span className="color-grey">Loading, please wait</span>
      </Progress>
    );
  }
}

// see https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da
decorate(Approvals, {
  exactDate: observable,
  handleToggleExactDate: action,
});

export default inject('approvalsStore')(withRouter(observer(Approvals)));
