/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import React from 'react';
import { observable, runInAction, decorate } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Table, Segment, Header, Icon } from 'semantic-ui-react';

import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import { isStoreError, isStoreLoading, isStoreEmpty } from '@aws-ee/base-ui/dist/models/BaseStore';
import BasicProgressPlaceholder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import EditableLabel from '../helpers/EditableLabel';

// expected props
// - study
// - structuredDataTable
class StructuredDataTableColumns extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.columnsStore = props.structuredDataTable.getColumnsStore();
    });
  }

  componentDidMount() {
    swallowError(this.columnsStore.load());
    this.columnsStore.startHeartbeat();
  }

  componentWillUnmount() {
    this.columnsStore.stopHeartbeat();
  }

  render() {
    const store = this.columnsStore;
    // Render loading, error, or tab content
    let content;
    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="m0" />;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={1} />;
    } else if (isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else {
      content = this.renderTable();
    }

    return content;
  }

  renderTable() {
    return (
      <>
        <Table striped className="scrollable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.columnsStore.columns.map(column => (
              <Table.Row key={column.id}>
                <Table.Cell>
                  <Icon name="columns" /> {column.id}
                </Table.Cell>
                <Table.Cell>{column.type}</Table.Cell>
                <Table.Cell>{this.renderEditableField(column, 'name', column.name)}</Table.Cell>
                <Table.Cell>{this.renderEditableField(column, 'description', column.description)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }

  renderEditableField(column, key, initialValue) {
    if (this.props.study.access === 'admin') {
      return (
        <EditableLabel
          enableKeys
          initialValue={initialValue || ''}
          save={async value => this.handleFieldChanged(column, key, value)}
        />
      );
    }

    return initialValue;
  }

  renderEmpty() {
    return (
      <Segment placeholder className="mt0">
        <Header icon className="color-grey">
          <Icon name="file outline" />
          No columns
          <Header.Subheader>No columns discovered yet</Header.Subheader>
        </Header>
      </Segment>
    );
  }

  async handleFieldChanged(column, key, value) {
    let name = column.name;
    let description = column.description;

    switch (key) {
      case 'name':
        name = value;
        break;
      case 'description':
        description = value;
        break;
      default:
        break;
    }

    await this.columnsStore.updateColumn(column.id, name, description);
  }
}

decorate(StructuredDataTableColumns, {
  filesStore: observable,
});

export default inject('study', 'structuredDataTable')(observer(StructuredDataTableColumns));
