/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

/* eslint-disable import/prefer-default-export */
import { types, getParent } from 'mobx-state-tree';
import { BaseStore } from '@aws-ee/base-ui/dist/models/BaseStore';

import { getStructuredDataTable } from '../../helpers/api';

// ==================================================================
// StructuredDataTableStore
// ==================================================================
const StructuredDataTableStore = BaseStore.named('StructuredDataTableStore')
  .props({
    id: types.identifier,
    tickPeriod: 300 * 1000, // 5 minutes
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const parent = getParent(self, 2);
        const rawData = await getStructuredDataTable(parent.studyId, self.id);

        parent.addStructuredDataTable(rawData);
      },
      cleanup: () => {
        superCleanup();
      },
    };
  })
  .views(self => ({
    get structuredDataTable() {
      const parent = getParent(self, 2);

      return parent.getStructuredDataTable(self.id);
    },
  }));

export { StructuredDataTableStore }; // eslint-disable-line import/prefer-default-export
