/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */

import _ from 'lodash';
import { getEnv, types } from 'mobx-state-tree';

const StudyProjectSelection = types.model('StudyProjectSelection', {}).views(self => ({
  /**
   * Returns project ids the selected studies are scoped to.
   *
   * E.g.,
   * - if selected studies are s1, s2, s3.
   * - s1 and s2 are scoped to project A, B.
   * - s3 is scoped to project B, C and D.
   * - This will return [[A,B],[A,B],[B,C,D]]
   *
   * @returns {string[][]}
   */
  get projectIdsOfSelectedStudies() {
    const studySelection = getEnv(self).filesSelection; // The 'filesSelection is incorrectly named. It should be 'studySelection'
    const selectedStudyOptions = studySelection.list;

    // studiesStoresMap is an object with key = studyCategoryId and value = StudiesStore MobX State Tree object for that study category
    const studiesStoresMap = getEnv(self).studiesStoresMap;
    const studyCategoryIds = _.keys(studiesStoresMap);

    // The selectedStudyOptions below has only few attributes of study and has the shape
    // [ {id, name, description, accessStatus} ] map them to array of study MobX model
    // objects to find project ids associated with the selected studies
    const selectedStudies = _.map(selectedStudyOptions, ({ id }) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const studyCategoryId of studyCategoryIds) {
        const studiesStore = studiesStoresMap[studyCategoryId];
        const study = studiesStore.getStudy(id);
        if (study) {
          return study;
        }
      }
      return undefined;
    });

    // The Open Data studies are not associated with any projects so exclude them when finding out project ids
    const studiesWithProjects = _.filter(selectedStudies, s => {
      return _.toLower(s.category) !== _.toLower('Open Data');
    });

    const projectIdArrays = _.map(studiesWithProjects, study => {
      if (study.projectId) {
        return _.isArray(study.projectId) ? study.projectId : [study.projectId];
      }
      return [];
    });
    return projectIdArrays;
  },

  /**
   * Returns all project ids the selected studies are scoped to.
   *
   * E.g.,
   * - if selected studies are s1, s2, s3.
   * - s1 and s2 are scoped to project A, B.
   * - s3 is scoped to project B, C and D.
   * - This will return [A,B,C,D]
   *
   * @returns {string}
   */
  get allSelectedProjectIds() {
    return _.uniq(_.flatten(self.projectIdsOfSelectedStudies));
  },

  /**
   * Returns common project ids that all the selected studies are scoped to
   *
   * E.g.,
   * - if selected studies are s1, s2, s3.
   * - s1 and s2 are scoped to project A, B, C.
   * - s3 is scoped to project B, C and D.
   * - This will return [B, C] i.e., the intersection
   *
   * @returns {string}
   */
  get commonSelectedProjectIds() {
    return _.intersection(...self.projectIdsOfSelectedStudies);
  },
}));

function registerContextItems(appContext) {
  appContext.studyProjectSelection = StudyProjectSelection.create({}, appContext);
}

export { StudyProjectSelection, registerContextItems };
