/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

function padZero(value) {
  if (value.length < 2) {
    value = `0${value}`;
  }

  return value;
}

function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);
  const year = padZero(date.getFullYear());
  const month = padZero((date.getMonth() + 1).toString());
  const dayOfMonth = padZero(date.getDate().toString());
  const hours = padZero(date.getHours().toString());
  const minutes = padZero(date.getMinutes().toString());
  const seconds = padZero(date.getSeconds().toString());

  return `${month}/${dayOfMonth}/${year} ${hours}:${minutes}:${seconds}`;
}

export default formatDateTime;
