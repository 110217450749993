/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import React from 'react';
import { observer } from 'mobx-react';
import { action, decorate, observable, runInAction } from 'mobx';
import { Button, Header, Modal, Segment } from 'semantic-ui-react';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';

import Input from '@aws-ee/base-ui/dist/parts/helpers/fields/Input';
import TextArea from '@aws-ee/base-ui/dist/parts/helpers/fields/TextArea';
import Form from '@aws-ee/base-ui/dist/parts/helpers/fields/Form';

import { getCreateStructuredDataTableForm } from '../../models/forms/CreateStructuredDataTableForm';

// expected props
// - study
class CreateStructuredDataTable extends React.Component {
  constructor(props) {
    super(props);
    runInAction(() => {
      this.cleanModal();
      this.form = getCreateStructuredDataTableForm();
      this.structuredDataTablesStore = props.study.getStructuredDataTableStore();
    });
  }

  cleanModal = () => {
    runInAction(() => {
      this.modalOpen = false;
    });
  };

  handleFormCancel = form => {
    form.clear();
    this.cleanModal();
  };

  handleFormError = (_form, _errors) => {};

  handleFormSubmission = async form => {
    try {
      const structuredDataTableValues = form.values();

      // Create structured data table, clear form, and close modal
      await this.structuredDataTablesStore.createStructuredDataTable(structuredDataTableValues);
      form.clear();
      this.cleanModal();
    } catch (error) {
      displayError(error);
    }
  };

  render() {
    const form = this.form;
    return (
      <Modal
        closeIcon
        trigger={this.renderTrigger()}
        open={this.modalOpen}
        onClose={action(() => {
          this.modalOpen = false;
        })}
      >
        <div className="mt2">
          <Header as="h3" icon textAlign="center" className="mt3" color="grey">
            Create Table
          </Header>
          <div className="mx3">
            <Segment clearing className="p3 mb3">
              <Form form={form} onCancel={this.handleFormCancel} onSuccess={this.handleFormSubmission}>
                {({ processing, /* onSubmit, */ onCancel }) => (
                  <>
                    <Input field={form.$('id')} />
                    <Input field={form.$('name')} />
                    <TextArea field={form.$('description')} />
                    <Button className="ml2" floated="right" color="blue" icon disabled={processing} type="submit">
                      Create Table
                    </Button>
                    <Button floated="right" disabled={processing} onClick={onCancel}>
                      Cancel
                    </Button>
                  </>
                )}
              </Form>
            </Segment>
          </div>
        </div>
      </Modal>
    );
  }

  renderTrigger() {
    return (
      <Button
        floated="right"
        color="blue"
        basic
        onClick={action(() => {
          this.modalOpen = true;
        })}
      >
        Create Table
      </Button>
    );
  }
}

decorate(CreateStructuredDataTable, {
  form: observable,
  modalOpen: observable,
  structuredDataTablesStore: observable,
  cleanModal: action,
  handleFormSubmission: action,
});

export default observer(CreateStructuredDataTable);
