/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License").
 * You may not use this file except in compliance with the License.
 * A copy of the License is located at
 *
 * http://aws.amazon.com/apache2.0
 *
 * or in the "license" file accompanying this file. This file is distributed
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 * express or implied. See the License for the specific language governing
 * permissions and limitations under the License.
 */

import React from 'react';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { isStoreLoading, isStoreReady, isStoreError } from '@aws-ee/base-ui/dist/models/BaseStore';
import { swallowError } from '@aws-ee/base-ui/dist/helpers/utils';
import ErrorBox from '@aws-ee/base-ui/dist/parts/helpers/ErrorBox';
import ProgressPlaceHolder from '@aws-ee/base-ui/dist/parts/helpers/BasicProgressPlaceholder';
import DataExtractionFileList from '@aws-ee/data-extraction-ui/dist/parts/environment/DataExtractionFileListComponent';

class DataExtractionApprovalCard extends React.Component {
  componentDidMount() {
    swallowError(this.props.scEnvironmentsStore.load());
  }

  render() {
    const { request, scEnvironmentsStore: store } = this.props;
    if (request.type !== 'data-extraction') {
      return null;
    }

    const uniqueApprovals = _.uniqBy(request.approvals, 'payload.environmentId');
    const enrichedApprovals = _.map(uniqueApprovals, approval => ({
      ...approval,
      environment: store.getScEnvironment(approval.payload.environmentId),
    }));

    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0" />;
    } else if (isStoreLoading(store)) {
      content = <ProgressPlaceHolder />;
    } else if (isStoreReady(store)) {
      content = (
        <>
          {_.map(enrichedApprovals, approval => {
            const extractionRequest = {
              environmentId: approval.payload.environmentId,
              id: JSON.stringify({
                id: approval.payload.environmentId,
                userCreatedAt: approval.payload.userCreatedAt,
              }),
            };
            return (
              <div key={approval.id} className="mb2">
                <p>{`Files extracted from workspace ${approval.environment.name}`}</p>
                <DataExtractionFileList dataExtractionRequest={extractionRequest} allowDownloads />
              </div>
            );
          })}
        </>
      );
    } else {
      content = null;
    }

    return content;
  }
}

export default inject('scEnvironmentsStore')(observer(DataExtractionApprovalCard));
